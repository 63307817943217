import { injectable } from 'inversify';
import { BaseApi } from '@/services/BaseApi';
import { IProfileApi } from '@/services/IProfileApi';
import { CreateProfileDto, CreateProfileResponseDto } from '@/types/webapi';

@injectable()
export default class ProfileApi extends BaseApi implements IProfileApi {
    registerProfile(createProfile: CreateProfileDto): Promise<CreateProfileResponseDto> {
        this.logger.log('[ProfileApi.registerProfile] Registering profile', createProfile);
        return this.httpPost<CreateProfileResponseDto>('/api/v1.0/profile', createProfile);
    }
}
