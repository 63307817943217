import { injectable } from 'inversify';
import { BaseApi } from './BaseApi';
import { ITravelCardsApi } from './ITravelCardsApi';
import type { TravelCardDto, TravelCardDataDto, InitiatePaymentResponseDto, OrderTravelCardDto } from '@/types/webapi';
import { AxiosError } from 'axios';

@injectable()
export default class TravelCardsApi extends BaseApi implements ITravelCardsApi {
    async list() {
        this.logger.log('[TravelCardsApi.list] Fetching all travelCards');
        return await this.httpGet<TravelCardDto[]>('/api/v1.0/travelcards');
    }

    async get(id: string) {
        this.logger.log('[TravelCardsApi.get] Fetching single travelCard', id);
        return await this.httpGet<TravelCardDataDto>('/api/v1.0/travelcards/' + id);
    }

    async register(id: string, name: string) {
        this.logger.log('[TravelCardsApi.register] Registering travelCard', id, name);
        return await this.httpPut<TravelCardDto>('/api/v1.0/travelcards', {
            id,
            name
        });
    }

    async validate(id: string) {
        this.logger.log('[TravelCardsApi.register] Validating travelCard', id);
        return await this.httpGet<boolean>(`/api/v1.0/travelcards/${id}/validate`);
    }

    async orderTravelCard(orderDto: OrderTravelCardDto) : Promise<InitiatePaymentResponseDto> {
        this.logger.log('[TravelCardsApi.orderTravelCard] Ordering travelCard', orderDto);
        return await this.httpPost<InitiatePaymentResponseDto>('/api/v1.0/travelcards/ordertravelcard', orderDto);
    }

    errorHandler(e: AxiosError) {
        switch (e.response?.status) {
            case 404:
                return new Error('errorMessages.travelCards.notFound');
            case 400:
                return new Error('errorMessages.travelCards.invalidTravelCardDetails');
            case 500:
                return new Error('errorMessages.api.unhandledException');
            default:
                return super.errorHandler(e);
        }
    }
}
