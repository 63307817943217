import { inject, injectable } from 'inversify';
import { ILoggerId, ILogger } from '@/services/ILogger';
import { AxiosInstance, isAxiosError } from 'axios';
import { IAxiosId } from '@/plugins/axios';

@injectable()
export abstract class BaseApi {
    @inject(ILoggerId)
    logger: ILogger;

    @inject(IAxiosId)
    http: AxiosInstance;

    httpGet<T>(url: string, data?: any, timeout?: number) {
        return new Promise<T>((resolve, reject) => {
            this.logger.log('[BaseApi.get] Making GET request', url, data);
            this.http
                .get<T>(url, { ...data, timeout })
                .then(response => {
                    this.logger.log('[BaseApi.get] Received response', response);
                    resolve(response.data);
                })
                .catch(e => {
                    if (this.handleAuthError(e)) {
                        resolve(null as T);
                    }

                    reject(this.errorHandler(e));
                });
        });
    }

    httpPost<T>(url: string, data?: any) {
        return new Promise<T>((resolve, reject) => {
            this.logger.log('[BaseApi.post] Making POST request', url, data);
            this.http
                .post<T>(url, data)
                .then(response => {
                    this.logger.log('[BaseApi.post] Received response', response);
                    resolve(response.data);
                })
                .catch(e => {
                    if (this.handleAuthError(e)) {
                        resolve(null as T);
                    }

                    reject(this.errorHandler(e));
                });
        });
    }

    httpPut<T>(url: string, data?: any) {
        return new Promise<T>((resolve, reject) => {
            this.logger.log('[BaseApi.put] Making PUT request', url, data);
            this.http
                .put<T>(url, data)
                .then(response => {
                    this.logger.log('[BaseApi.put] Received response', response);
                    resolve(response.data);
                })
                .catch(e => {
                    if (this.handleAuthError(e)) {
                        resolve(null as T);
                    }

                    reject(this.errorHandler(e));
                });
        });
    }

    handleAuthError(e: unknown): boolean {
        if (isAxiosError(e) && (e.response?.status === 401 || e.response?.status === 403)) {
            this.logger.log('[BaseApi.handleAuthError] Auth error. Redirecting to login page.');
            window.location.href = `/Authenticate/?returnUrl=${encodeURI(window.location.href)}`;

            return true;
        }

        return false;
    }

    errorHandler(e: unknown) {
        if (!isAxiosError(e)) return e;
        
        switch (e.response?.status) {
            case 404:
                return new Error('errorMessages.api.notFound');
            case 400:
                return new Error(e.response?.data || 'errorMessages.api.badRequest');
            default:
                return new Error(e.response?.data || 'An error occurred');
        }
    }
}
