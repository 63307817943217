import { injectable } from 'inversify';
import { BaseApi } from './BaseApi';
import { ICityLookupApi } from '@/services/ICityLookupApi';

@injectable()
export default class CityLookupApi extends BaseApi implements ICityLookupApi {
    getCityNameByPostalCode(apiUrl: string, postalCode: string): Promise<any> {
        this.logger.log('[CityLookupApi.getCityNameByPostalCode] Fetching City from ' + apiUrl + ' for post code ' + postalCode);

        return this.httpGet(apiUrl + postalCode);
    }
}
