import { CreateProfileDto, CreateProfileResponseDto } from '@/types/webapi';

export const IProfileApiId = Symbol.for('IProfileApi');

export interface IProfileApi {
    /**
     * Registers a new profile.
     */
    registerProfile(createProfile: CreateProfileDto): Promise<CreateProfileResponseDto>;
}
