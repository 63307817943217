import type { TravelCardDto, TravelCardDataDto, InitiatePaymentResponseDto, OrderTravelCardDto } from '@/types/webapi';

export const ITravelCardsApiId = Symbol.for('ITravelCardsApi');

export interface ITravelCardsApi {
    /**
     * Fetches travel card list for current user.
     */
    list(): Promise<TravelCardDto[]>;

    /**
     * Fetches travel card details
     * @param id Travel card ID
     */
    get(id: string): Promise<TravelCardDataDto>;

    /**
     * Registers a new travel card to the current user.
     * @param id Travel card number
     * @param name Travel card name
     */
    register(id: string, name: string): Promise<TravelCardDto>;

    /**
     * Validates the travel card against the FARA system.
     * @param id Travel card number
     */
    validate(id: string): Promise<boolean>;

    /**
     * Initiates a payment for a travel card order.
     */
    orderTravelCard(orderDto: OrderTravelCardDto): Promise<InitiatePaymentResponseDto>;
}
